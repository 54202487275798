import Matter from "matter-js";

/** Set up relative positions and scales **/
var VIEW = {};
VIEW.SAFE_WIDTH = 100;
VIEW.SAFE_HEIGHT = 100;
VIEW.scale = Math.min(
  window.innerWidth / VIEW.SAFE_WIDTH,
  window.innerHeight / VIEW.SAFE_HEIGHT
);
VIEW.width = window.innerWidth / VIEW.scale;
VIEW.height = window.innerHeight / VIEW.scale;
VIEW.centerX = VIEW.width / 2;
VIEW.centerY = VIEW.height / 2;

if (
  window.innerWidth / VIEW.SAFE_WIDTH >
  window.innerHeight / VIEW.SAFE_HEIGHT
) {
  VIEW.offsetX = 0;
  VIEW.offsetY = (2 * (VIEW.height - VIEW.SAFE_HEIGHT)) / 2 / VIEW.scale;
} else {
  VIEW.offsetX = (2 * (VIEW.width - VIEW.SAFE_WIDTH)) / 2 / VIEW.scale;
  VIEW.offsetY = 0;
}

// Matter.js module aliases
var Engine = Matter.Engine,
  Render = Matter.Render,
  World = Matter.World,
  Runner = Matter.Runner,
  Bodies = Matter.Bodies,
  Mouse = Matter.Mouse,
  MouseConstraint = Matter.MouseConstraint;

var engine = Engine.create();
engine.world.gravity.scale = 0.0003;

var render = Render.create({
  element: document.getElementById("wrapper"),
  engine: engine,
  options: {
    // width: VIEW.width - 2 * VIEW.offsetX,
    // height: VIEW.height + VIEW.offsetY,
    width: VIEW.width,
    height: VIEW.height,
    background: "none",
    // wireframeBackground: "#ff0000"
    wifreframe: true
    // options: {
    //     hasBounds: true,
    //     wireframes: false,
    //     background: "rgb(252,243,241)"
    //   }
    // hasBounds: false,
    // enabled: true,
    // wireframes: true,
    // showSleeping: true,
    // showDebug: false,
    // showBroadphase: false,
    // showBounds: false,
    // showVelocity: false,
    // showCollisions: false,
    // showAxes: false,
    // showPositions: false,
    // showAngleIndicator: false,
    // showIds: false,
    // showShadows: false
  }
});

var runner = Runner.create();
Runner.run(runner, engine);
// create a Matter.js engine

var ground = Bodies.rectangle(
  VIEW.width / 2,
  VIEW.height + 100,
  VIEW.width,
  200,
  {
    isStatic: true
  }
);

var ceiling = Bodies.rectangle(VIEW.width / 2, -200, VIEW.width, 200, {
  isStatic: true
});

var leftWall = Bodies.rectangle(
  -100,
  VIEW.height / 2 - 50,
  200,
  VIEW.height + 100,
  {
    isStatic: true
  }
);

var rightWall = Bodies.rectangle(
  VIEW.width + 100,
  VIEW.height / 2 - 50,
  200,
  VIEW.height + 100,
  {
    isStatic: true
  }
);

// add all of the bodies to the world
World.add(engine.world, [ground, ceiling, leftWall, rightWall]);

// add mouse control
var mouse = Mouse.create(render.canvas),
  mouseConstraint = MouseConstraint.create(engine, {
    mouse: mouse,
    constraint: {
      stiffness: 0.2,
      render: {
        visible: false
      }
    }
  });

World.add(engine.world, mouseConstraint);

// keep the mouse in sync with rendering
render.mouse = mouse;

// run the engine
Engine.run(engine);

Render.run(render);

var bodiesDom = document.querySelectorAll(".block-content");
var bodies = [];
for (var i = 0, l = bodiesDom.length; i < l; i++) {
  var body = Bodies.rectangle(
    VIEW.centerX,
    i * 4,
    (VIEW.width * bodiesDom[i].offsetWidth) / window.innerWidth,
    (VIEW.height * bodiesDom[i].offsetHeight) / window.innerHeight,
    { restitution: 0.7 }
  );
  bodiesDom[i].id = body.id;
  bodies.push(body);
}
World.add(engine.world, bodies);

window.requestAnimationFrame(update);
function update() {
  for (var i = 0, l = bodiesDom.length; i < l; i++) {
    var bodyDom = bodiesDom[i];
    var body = null;
    for (var j = 0, k = bodies.length; j < k; j++) {
      if (bodies[j].id == bodyDom.id) {
        body = bodies[j];
        break;
      }
    }

    if (body === null) continue;

    bodyDom.style.transform =
      "translate( " +
      (VIEW.offsetX + body.position.x * VIEW.scale - bodyDom.offsetWidth / 2) +
      "px, " +
      (VIEW.offsetY + body.position.y * VIEW.scale - bodyDom.offsetHeight / 2) +
      "px )";
    bodyDom.style.transform += "rotate( " + body.angle + "rad )";
  }
  window.requestAnimationFrame(update);
}
